import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { LayoutSinglePage } from "components";
import FacebookIcon from "../components/Facebook/facebook";
import InstagramIcon from "../components/Instagram/instagram";
import YoutubeIcon from "../components/Youtube/youtube";

const ContactUsPage = ({ location, data }) => {
  return (
    <Layout location={location} pageTitle="Contact us - Reprocess & Repurpose">
      <LayoutSinglePage data={data.contentfulPages} columns={2}>
        <div className="flex lg:justify-end justify-center">
          <ul className="social-icons flex items-center justify-center md:justify-end mt-10 mb-8">
            <li className="mx-3">
              <a
                href="https://www.instagram.com"
                className="flex items-center justify-center border border-white rounded-full h-12 w-12 text-white fill-current hover:bg-white hover:bg-opacity-50"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
            <li className="mx-3">
              <a
                href="https://www.facebook.com"
                className="flex items-center justify-center border border-white rounded-full h-12 w-12 text-white fill-current hover:bg-white hover:bg-opacity-50"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </li>
            <li className="mx-3">
              <a
                href="https://www.youtube.com"
                className="flex items-center justify-center border border-white rounded-full h-12 w-12 text-white fill-current hover:bg-white hover:bg-opacity-50"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </li>
          </ul>
        </div>
      </LayoutSinglePage>
    </Layout>
  );
};

export const query = graphql`
  query ContactUsPageQuery {
    contentfulPages(slug: { eq: "contact-us" }) {
      body {
        raw
      }
      background {
        gatsbyImageData
      }
      id
      title
    }
  }
`;

export default ContactUsPage;
